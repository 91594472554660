.container {
  margin: 50px auto 0;
  display: flex;
  height: calc(100vh - 100px);
  overflow: hidden;
}

.aside {
  width: 260px;
  flex-shrink: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
}

.listHeader {
  font-size: 18px;
  font-weight: bold;
  padding: 6px 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.list {
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
}

.listItem {
  padding: 12px 18px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  font-size: 16px;
  transition: all 0.1s ease-out;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.listItem:hover {
  background: #d5e6d9;
}

.listItemRemoved {
  background: #f5c6cb;
}

.listItemActive {
  background: #c3e6cb;
}
.listItemActive:hover {
  background: #c3e6cb;
}

.fileName {
  display: block;
  margin-left: 8px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content {
  width: 100%;
  min-width: 0;
}

.info {
  height: 100%;
  overflow: auto;
  padding: 24px;
  position: relative;
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-size: 24px;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.label {
  width: 150px;
  font-weight: bold;
  font-size: 14px;
  flex-shrink: 0;
}

.value {
  font-size: 16px;
}

.break {
  word-break: break-word;
}

.table {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.tableCol {
  margin-top: 20px;
}

.tableCell {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  height: 33px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.05s ease-out;
  border: 1px solid #dee2e6;
  padding: 0.3rem;
}

.tableHead {
  text-align: center;
  font-weight: bold;
}

.block {
  display: flex;
  align-items: flex-start;
}

.left {
  flex: 1;
  min-width: 0;
}