:global html {
  --app-info: 135, 206, 235;
  --app-text-muted: 112, 101, 123;
  --app-text: 71, 64, 79;
  --app-text-light: 98, 98, 98;
  --app-text-dark: 0, 0, 0;
  --app-success: 76, 175, 80;
  --app-danger: 181, 76, 63;
  --app-warning: 255, 193, 7;
  --app-content-color: 98, 98, 98;
  --app-heading-color: 44, 44, 44;
  --app-label-color: 98, 112, 132;
  --app-primary: 115, 103, 240;
}
:global html.highContrast {
  --app-info: 135, 206, 235;
  --app-text-muted: 89, 80, 98;
  --app-text: 0, 0, 0;
  --app-text-light: 0, 0, 0;
  --app-text-dark: 0, 0, 0;
  --app-primary: 62, 63, 143;
  --app-success: 33, 163, 91;
  --app-danger: 181, 76, 63;
  --app-warning: 255, 193, 7;
  --app-content-color: 0, 0, 0;
  --app-heading-color: 0, 0, 0;
  --app-label-color: 63, 72, 85;
}