.aside {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: auto;
  padding-left: 0.5em;
}

.wrapper {
  background: #343a40;
  padding: 0.5rem 1rem;
  display: flex;
}

.input {
  margin-left: 0.5em;
  width: 350px;
}