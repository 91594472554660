.app {
  height: 100%;
}

:global html, :global body, :global #app {
  height: 100%;
  width: 100%;
}
:global #app {
  flex-direction: column;
  display: flex;
}
:global .Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  flex-shrink: 0;
}
:global .Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}
:global .Resizer.horizontal {
  height: 15px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}
:global .Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}
:global .Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}
:global .Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
:global .Resizer.disabled {
  cursor: not-allowed;
}
:global .Resizer.disabled:hover {
  border-color: transparent;
}
:global .table-bordered {
  width: auto;
}
:global .table-bordered th, :global .table-bordered td {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.05s ease-out;
}