.upload {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
}

.uploadBlock {
  position: relative;
  flex: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px;
}
.uploadBlock:last-child {
  border-bottom: 0;
}

.uploadTitle {
  font-size: 24px;
  margin-bottom: 12px;
}

.uploadText {
  font-size: 24px;
  margin-bottom: 8px;
}

.uploadOr {
  font-size: 14px;
  margin-bottom: 8px;
}

.progress {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  font-size: 24px;
}

.bar {
  width: 80%;
}

.btn {
  position: relative;
  overflow: hidden;
}
.btn input {
  position: absolute;
  right: 0;
  top: 0;
  width: 200%;
  height: 200%;
  opacity: 0;
}